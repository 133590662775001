.contentIntervenantNewPT{
    width : 100%;
    margin-top : 90px;
    padding-top : 10px;
    padding-left: 30%;
    padding-right: 1%;
}

@media (max-width: 1290px) {
    .contentIntervenantNewPT{

        padding-left: 35%;
    }
}
.bandeauDateI{
    background-color: #4aabe7;
    /* position: fixed; */
    height: 77px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    width: 100%;
}

.dateJourI{
    font-size: 32px;

}
@media screen and (max-width: 768px) {
    .dateJourI{
        font-size: 20px;
    }
}
.boutonIntervenant{
    width: 100%;
    background-color: #7ecac8;
    height: auto;
    padding: 10px;
    color: #fff;
    border: none;
    border-radius: 5px;
}
.searchI button{
    width: 170px;
    background-color: #7ecac8;
    height: 40px;
    padding: 10px;
    color: #fff;
    border: 1px solid #7ecac8;
    border-radius: 5px;
    margin-right: 25px;
}
.searchI button span{
    margin-left: 5px;
}
.bandeauValidationI{
    background-color: #072872;
    color: white;
    height: 125px;
    /* width : 40%; */
    margin-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    width: 400px;
    /* margin-left: px; */
    position: static;
}

.intervenants-table{
    margin-left: 20px;
}

.intervenants-table-header tr {
    width: 63%;
}

.buttonValidationI{
    background-color: #7ECAC8;
    margin-left: 24px;
}

@media (max-width : 1300px) {
    .bandeauValidationI {

        font-size: 12px;
        /* margin: 100px 40%; */
    }
}

@media screen and (max-width: 768px) {
    .bandeauValidationI{
        width: unset;
        margin-left: 4px;
    }
    .intervenants-table{
        font-size: 10px;
        margin-left: 4px;
    }

    .intervenants-table-header tr {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .bandeauValidationI{
        flex-direction: column;
    }

    .buttonValidationI{
        background-color: #7ECAC8;
        margin-left: 0px;
        margin-bottom: 4px;
    }
}


.bandeauValidationI span {
    color: lightgray;
}

.tableInter{
    width: calc(100% - 520px);
    margin-top: 50px;
}
.table>:not(:first-child) {
    border-top: 2px solid lightgray;
}